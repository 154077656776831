/*eslint-disable*/
import React, { lazy, Suspense } from 'react'
const Home = lazy(() => import('../page/Home'))

const MyNft = lazy(() => import('../page/MyNFT'))

const MyAssets = lazy(() => import('../page/MyAssets'))

const AssetsRecord = lazy(() => import('../page/Record/AssetsRecord'))

const Notice = lazy(() => import('../page/Notice/Notice'))
const NoticeChild = lazy(() => import('../page/Notice/NoticeChild'))

const MyAI = lazy(() => import('../page/MyAI'))
const NftTokenMining = lazy(() => import('../page/NftTokenMining'))
const MyNode = lazy(() => import('../page/MyNode'))
const MyAIRobot = lazy(() => import('../page/MyAIRobot'))
const AIFinance = lazy(() => import('../page/Record/AIFinance'))
const ReferrerBenefits = lazy(() => import('../page/Record/ReferrerBenefits'))
const ComBenefits = lazy(() => import('../page/Record/ComBenefits'))
const DirectPushBenefits = lazy(() =>
  import('../page/Record/DirectPushBenefits')
)
const NFTMiningDetail = lazy(() => import('../page/Record/NFTMiningDetail'))
const AIFinanceDetail = lazy(() => import('../page/Record/AIFinanceDetail'))
const MyNboBonusPlan = lazy(() => import('../page/MyNboBonusPlan'))
const MyBonusPlan = lazy(() => import('../page/MyBonusPlan'))
const BonusPlanDetail = lazy(() => import('../page/Record/BonusPlanDetail'))
const CommBenefits = lazy(() => import('../page/Record/CommBenefits'))
const DirectPushList = lazy(() => import('../page/Record/DirectPushList'))
const DestructionPool = lazy(() => import('../page/Record/DestructionPool'))
const BurnDividends = lazy(() => import('../page/BurnDividends'))

const MyBurnDividends = lazy(() => import('../page/MyBurnDividends'))

const DestructionRecord = lazy(() => import('../page/Record/DestructionRecord'))
const WithDirectRecommendationList = lazy(() => import('../page/Record/WithDirectRecommendationList'))
const LPDividends = lazy(() => import('../page/LPDividends'))
const LPDividendsList = lazy(() => import('../page/Record/LPDividendsList'))
const DirectPushIncome = lazy(() => import('../page/Record/DirectPushIncome'))

import Layout from '../components/Layout'
import { getToken } from '../utils/token.js'
import { Skeleton } from 'antd-mobile'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Loading from './Loading'
// import MyAssets from '../page/MyAssets'
// import BuyNFT from '../page/BuyNFT';
function RequireAuth ({ children }) {
  const authed = getToken()
  return authed ? ( // 判断 authed 中登录状态是否为 true
    children // 嵌套传的
  ) : (
    <Navigate to="/login" replace /> // 跳转到登录
  )
}
const SkeletonLoading = () => {
  return (
    <div>
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={10} animated />
    </div>
  )
}
const BaseRouter = () => (
  <Router>
    <Routes>
      {/* <Route path="/home" element={<Home />} />  */}
      <Route path="/" element={<Layout />}>
        <Route
          path="/home"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/myNft"
          element={
            <Suspense fallback={<Loading />}>
              <MyNft />
            </Suspense>
          }
        />
        <Route
          path="/noticeList"
          element={
            <Suspense fallback={<Loading />}>
              <Notice />
            </Suspense>
          }
        />
        <Route
          path="/noticeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <NoticeChild />
            </Suspense>
          }
        />
        <Route
          path="/myAssets"
          element={
            <Suspense fallback={<Loading />}>
              <MyAssets />
            </Suspense>
          }
        />
        <Route
          path="/recordList"
          element={
            <Suspense fallback={<Loading />}>
              <AssetsRecord />
            </Suspense>
          }
        />
        <Route
          path="/myAI"
          element={
            <Suspense fallback={<Loading />}>
              <MyAI />
            </Suspense>
          }
        />
        <Route
          path="/nftTokenMining"
          element={
            <Suspense fallback={<Loading />}>
              <NftTokenMining />
            </Suspense>
          }
        />
        <Route
          path="/myNode"
          element={
            <Suspense fallback={<Loading />}>
              <MyNode />
            </Suspense>
          }
        />
        <Route
          path="/myAI/RobotInfo"
          element={
            <Suspense fallback={<Loading />}>
              <MyAIRobot />
            </Suspense>
          }
        />
        <Route
          path="/aiFinance"
          element={
            <Suspense fallback={<Loading />}>
              <AIFinance />
            </Suspense>
          }
        />
        <Route
          path="/referrerBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <ReferrerBenefits />
            </Suspense>
          }
        />
        <Route
          path="/comBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <ComBenefits />
            </Suspense>
          }
        />
        <Route
          path="/nftMiningDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <NFTMiningDetail />
            </Suspense>
          }
        />
        <Route
          path="/aiFinanceDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <AIFinanceDetail />
            </Suspense>
          }
        />
        <Route
          path="/myNboBonusPlan"
          element={
            <Suspense fallback={<Loading />}>
              <MyNboBonusPlan />
            </Suspense>
          }
        />
        <Route
          path="/myBonusPlan"
          element={
            <Suspense fallback={<Loading />}>
              <MyBonusPlan />
            </Suspense>
          }
        />
        <Route
          path="/bonusPlanDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <BonusPlanDetail />
            </Suspense>
          }
        />
        <Route
          path="/directPushBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushBenefits />
            </Suspense>
          }
        />
        <Route
          path="/commBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <CommBenefits />
            </Suspense>
          }
        />
        <Route
          path="/directPushList"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushList />
            </Suspense>
          }
        />
        <Route
          path="/destructionPool"
          element={
            <Suspense fallback={<Loading />}>
              <DestructionPool />
            </Suspense>
          }
        />
        <Route
          path="/burnDividends"
          element={
            <Suspense fallback={<Loading />}>
              <BurnDividends />
            </Suspense>
          }
        />
        <Route
          path="/myBurnDividends"
          element={
            <Suspense fallback={<Loading />}>
              <MyBurnDividends />
            </Suspense>
          }
        />
        <Route
          path="/destructionRecord"
          element={
            <Suspense fallback={<Loading />}>
              <DestructionRecord />
            </Suspense>
          }
        />
        <Route
          path="/withDirectRecommendationList"
          element={
            <Suspense fallback={<Loading />}>
              <WithDirectRecommendationList />
            </Suspense>
          }
        />
        <Route
          path="/lpDividends"
          element={
            <Suspense fallback={<Loading />}>
              <LPDividends />
            </Suspense>
          }
        />
        <Route
          path="/lpDividendsList"
          element={
            <Suspense fallback={<Loading />}>
              <LPDividendsList />
            </Suspense>
          }
        />
        <Route
          path="/directPushIncome"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushIncome />
            </Suspense>
          }
        />





      </Route>
    </Routes>
  </Router>
)

export default BaseRouter
