
/*eslint-disable*/
import request from './request'

//提交绑定地址
// export const BindRelationshipApi = (params) => request.post('/user/bindRelationship', params)



//首页公告
export const getHomeNoticeApi = (params) => request.get('/notice/get_notice', { params })


//公告列表
export const getNoticeListApi = (params) => request.get('/notice/get_notice_list', { params })

//1.获取用户是否存在
export const PostExistUserApi = (params) => request.post('/user/exist_user', params)

//签名
export const PostSignApi = (params) => request.post('/signed/check_signed', params)
// //激活用户
export const PostSetRefApi = (params) => request.post('/user/bind', params)
//个人中心数据
export const getUserInfoApi = (params) => request.get('/user/user_info', { params })
//轮播图
export const getBannerApi = (params) => request.get('/banner/get_banner', { params })

//提现操作
export const PostWithdrawApi = (params) => request.post('/withdraw_record/withdraw', params)

//获取资产
export const getAssetsInfoApi = (params) => request.get('/user/user_info', { params })

//闪兑操作
export const PostExchangeApi = (params) => request.post('/exchange_record/exchange', params)


//获取AI金融配置
export const getAIRobotConfigApi = (params) => request.get('/finance/get_finance_config', { params })

// //AI金融投资操作
// export const PostFinanceOperateApi = (params) => request.post('/finance/finance_operate', params)


//我的AI机器人列表
export const getAIRobotListApi = (params) => request.get('/finance/get_finance_list', { params })

//AI金融推荐奖
export const getRecommendationAwardApi = (params) => request.get('/finance/get_recommendation_award', { params })

//获取推荐人福利
export const getReferrerBenefitsApi = (params) => request.get('/finance/get_referrer_benefits', { params })

//获取AI订单详情
export const getFinanceDetailsApi = (params) => request.get('/finance/get_finance_details', { params })

//获取NFT配置
export const getNFTConfigApi = (params) => request.get('/nft/get_nft_config', { params })

//获取NFT记录列表
export const getNFTRecordApi = (params) => request.get('/nft/nft_record', { params })

//获取NFT挖矿详情
export const getNFTDetailApi = (params) => request.get('/nft/get_nft_details', { params })

//社区奖励列表
export const getNftCommunityAwardApi = (params) => request.get('/nft/get_nft_community_award', { params })

//我的机器人数据
export const getMyFinanceDataApi = (params) => request.get('/finance/my_finance_data', { params })

//我的节点数据
export const getMyNodeApi = (params) => request.get('/user/my_node', { params })

//资金明细列表
export const getFundDetailApi = (params) => request.get('/record/funding_details', { params })


//质押操作
export const PostPledgeOperationApi = (params) => request.post('/pledge/pledge_operation', params)

//我的质押社区
export const getMyCommunityApi = (params) => request.get('/pledge/my_community', { params })


//质押订单收益
export const getOrderIncomeApi = (params) => request.get('/pledge/order_income', { params })

//质押直推收益列表
export const getDirectPushIncomeApi = (params) => request.get('/pledge/direct_push_income', { params })

//质押团队收益
export const getTeamIncomeApi = (params) => request.get('/pledge/team_income', { params })

//质押直推用户列表
export const getDirectPushListApi = (params) => request.get('/pledge/direct_push_list', { params })

//销毁接口
export const getDestroyListApi = (params) => request.get('/pledge/destroy_list', { params })

//首页销毁配置
export const getDestroyConfigIndexApi = (params) => request.get('/destroy/destroy_config_index', { params })

//首页销毁配置
export const getDestroyConfigListApi = (params) => request.get('/destroy/destroy_config_list', { params })

//我的销毁记录
export const getMyDestroyRecordApi = (params) => request.get('/destroy/my_destroy_record', { params })


//销毁记录（销毁分红）
export const getDestroyHashListApi = (params) => request.get('/destroy/destroy_hash_list', { params })

//判断是否可质押CFDAO
export const getCanPledgeCfdaoApi = (params) => request.get('/user_bind/can_pledge_cfdao', { params })

//待绑定列表
export const getUserBindListApi = (params) => request.get('/user_bind/get_user_bind_list', { params })

//绑定
export const PostUserBindOperationApi = (params) => request.post('/user_bind/user_bind_operation', params)


//LP分红记录
export const getLpDividendsApi = (params) => request.get('/lp/my_lp_order', { params })

//LP分红记录列表
export const getLpDividendsListApi = (params) => request.get('/lp/my_lp_income', { params })

//直推收益列表
export const getDirectPushIncomeListApi = (params) => request.get('/lp/my_direct_push_lp_income', { params })

